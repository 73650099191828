import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LiveCallsDataService {
  private LivecallsDataSubject = new BehaviorSubject<any>(null);
  public LivecallsData = this.LivecallsDataSubject.asObservable();

  private VisibleSubject = new BehaviorSubject<boolean>(false);
  public VisibleData = this.VisibleSubject.asObservable();

  private CoatoDataSubject = new BehaviorSubject<any>(null);
  public CoatoData = this.CoatoDataSubject.asObservable();

  sendData(data) {
    this.LivecallsDataSubject.next(data);
  }

  sendVisibleData(data) {
    this.VisibleSubject.next(data);
  }

  sendCoatodata(data) {
    this.CoatoDataSubject.next(data);
  }
}
